import { useEffect, useState } from "react";
import "./App.css";

const App = () => {
  const [datetime, setMille] = useState(Date.now());

  useEffect(() => {
    const timer = setInterval(() => {
      setMille((prev) => prev + 1000);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const hours = (new Date(datetime).getHours() / 24) * 360 - 90;
  const minutes = ((new Date(datetime).getHours() * 60 + new Date(datetime).getMinutes()) / 60) * 360 - 90;
  const seconds = ((new Date(datetime).getMinutes() * 60 + new Date(datetime).getSeconds()) / 60) * 360 - 90;

  return (
    <div className="App">
      <div className="clock">
        <div className="bg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="1.41"
            clipRule="evenodd"
            version="1.1"
            viewBox="0 0 460 460"
            xmlSpace="preserve"
          >
            <path d="M460 316.1c0 5.5 0 10.99-.04 16.48-.03 4.63-.08 9.26-.2 13.88a201.73 201.73 0 0 1-2.66 30.21c-1.71 10-4.9 19.68-9.47 28.73a96.6 96.6 0 0 1-42.23 42.23 101.86 101.86 0 0 1-28.71 9.46c-10 1.65-20.1 2.54-30.22 2.66a649 649 0 0 1-13.88.21c-5.5.03-10.99.03-16.48.03H143.89c-5.49 0-10.98 0-16.48-.03a648.8 648.8 0 0 1-13.88-.2 201.46 201.46 0 0 1-30.22-2.67c-9.99-1.7-19.67-4.9-28.71-9.46a96.61 96.61 0 0 1-42.23-42.22 101.96 101.96 0 0 1-9.47-28.74 201.6 201.6 0 0 1-2.66-30.2c-.12-4.63-.18-9.26-.2-13.89C0 327.08 0 321.6 0 316.1V143.9c0-5.5 0-11 .04-16.5.02-4.62.08-9.25.2-13.87a201.64 201.64 0 0 1 2.66-30.2c1.71-10 4.9-19.68 9.47-28.74A96.6 96.6 0 0 1 54.6 12.36 101.96 101.96 0 0 1 83.3 2.9c10-1.64 20.1-2.53 30.22-2.66 4.63-.12 9.26-.18 13.88-.2 5.5-.03 11-.03 16.48-.03H316.1c5.5 0 11 0 16.49.03a649 649 0 0 1 13.88.2c10.12.13 20.22 1.02 30.21 2.66 10 1.71 19.67 4.9 28.72 9.46a96.58 96.58 0 0 1 42.24 42.23 101.92 101.92 0 0 1 9.46 28.73 201.7 201.7 0 0 1 2.66 30.21c.12 4.63.18 9.26.2 13.88.04 5.5.04 10.99.04 16.48V316.1z" />
          </svg>
        </div>
        <div className="fg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            version="1.1"
            viewBox="0 0 460 460"
            xmlSpace="preserve"
          >
            <circle cx="230" cy="230" r="200" fill="#fff" />
          </svg>
        </div>

        <div className="hours" style={{ transform: `translate(0%, -50%) rotate(${hours}deg)` }}></div>
        <div className="minutes" style={{ transform: `translate(0%, -50%) rotate(${minutes}deg)` }}></div>
        <div className="seconds" style={{ transform: `translate(0%, -50%) rotate(${seconds}deg)` }}></div>
      </div>
      <div className="time">{new Date(datetime).toLocaleString()}</div>
    </div>
  );
};

export default App;
